/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
// import { viVN } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../utils/store';
import { style } from '@/styles/globals';
import '../styles/globals.css';
import { isEmpty } from 'lodash';
// import { isBrowser } from '@/utils/dom';
import Script from 'next/script';
import { GA_TRACKING_ID } from 'lib/gtag';
import dynamic from 'next/dynamic';

const Layout = dynamic(() => import('@/components/Layout/Layout'));
const LayoutFont = dynamic(() => import('@/components/Layout/LayoutFont'));

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1192,
      xl: 1536,
      maxWidth: 1192,
      minWidth: 1024,
    },
  },
  palette: {
    ...style.color,
    ...style.spacingSize,
    borderRadius: style.borderRadius,
    boxShadow: style.boxShadow,
    primary: {
      main: style.color.product3,
    },
    secondary: {
      main: style.color.blue3,
    },
    text: {
      primary: style.color.black2,
      secondary: style.color.black3,
    },
    background: {
      default: '#1890FF',
    },
    error: {
      main: style.color.red2,
    },
    warning: {
      main: '#E1AD14',
    },
  },
  typography: {
    body2: {
      color: style.color.black2,
      ...style.text.content.regular,
    },
    h6: {
      color: style.color.black1,
      ...style.text.heading2,
    },
    h2: {
      color: style.color.black1,
      ...style.text.h2,
    },
    h3: {
      color: style.color.black2,
      ...style.text.h3,
    },
    body1: {
      color: style.color.black2,
      ...style.text.content.bold,
    },
    caption: {
      color: style.color.black2,
      ...style.text.caption.regular,
    },
    subtitle1: {
      color: style.color.black2,
      ...style.text.title,
      fontWeight: '700',
    },
    subtitle2: {
      color: style.color.black2,
      ...style.text.title,
      fontWeight: '500',
    },
    heading: {
      ...style.text.heading2,
      color: style.color.black1,
    },
    heading1: {
      ...style.text.heading1,
      color: style.color.black1,
    },
    heading2: {
      ...style.text.heading,
      color: style.color.black1,
    },
    heading3: {
      ...style.text.heading3,
      color: style.color.black1,
    },
    title: {
      ...style.text.title,
      color: style.color.black2,
    },
    contentBold: {
      ...style.text.content.bold,
      color: style.color.black2,
    },
    contentRegular: {
      ...style.text.content.regular,
      color: style.color.black2,
    },
    contentRegularBold: {
      ...style.text.content.regularBold,
      color: style.color.black2,
    },
    contentHyperlink: {
      ...style.text.content.hyperlink,
    },
    captionSmallBold: {
      ...style.text.caption.smallBold,
      color: style.color.black2,
    },
    captionMedium: {
      ...style.text.caption.medium,
      color: style.color.black2,
    },
    captionRegular: {
      ...style.text.caption.regular,
      color: style.color.black2,
    },
    captionItalic: {
      ...style.text.caption.italic,
      color: style.color.black2,
    },
    fontFamily: ['SVN-Circular', 'Roboto'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color: 'black',
          textTransform: 'inherit',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },

          borderRadius: '6px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 'auto',
        },
        label: {
          padding: '0.2em 0.5em',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#343A40',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '0.875rem',
          color: '#000000DE',
          '&:focus': {
            backgroundColor: '$labelcolor',
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: '#000000DE!important',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#00000099',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: 'inherit',
            WebkitTextFillColor: '#000000DE!important',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        input: {
          fontSize: '0.875rem',
          color: '#000000DE',
          background: 'inherit',
          '&:hover': {
            background: 'inherit',
          },
          '&.Mui-disabled': {
            background: 'inherit',
            WebkitTextFillColor: '#000000DE!important',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
    },
  },
});

function GYLApp({ Component, pageProps }: AppProps) {
  let LayoutCom = null;
  if (pageProps?.isSeo) {
    LayoutCom = Layout;
  } else {
    LayoutCom = LayoutFont;
  }

  const GTMScript = () => (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_TRACKING_ID}', {
        page_path: window.location.pathname,
      });
    `,
        }}
      />
    </>
  );
  if (isEmpty(pageProps)) {
    return (
      <>
        <GTMScript />
        <ThemeProvider theme={muiTheme}>
          <Provider store={store}>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <LayoutCom>
                <Component {...pageProps} />
              </LayoutCom>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </>
    );
  }
  return (
    <>
      <GTMScript />
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <LayoutCom>
            <Component {...pageProps} />
          </LayoutCom>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default GYLApp;

declare module '@mui/material/styles' {
  interface CustomPalette {
    xxSmall?: string;
    xSmall?: string;
    small?: string;
    medium?: string;
    large?: string;
    xLarge?: string;
    xxLarge?: string;
    xxxLarge?: string;

    product1?: string;
    product2?: string;
    product3?: string;
    product4?: string;
    white?: string;
    white1?: string;
    white2?: string;
    white3?: string;
    white4?: string;
    black1?: string;
    black2?: string;
    black3?: string;
    black4?: string;
    black5?: string;
    black6?: string;
    black7?: string;
    black8?: string;
    red2?: string;
    red3?: string;
    red4?: string;
    green2?: string;
    green3?: string;
    green4?: string;
    orange2?: string;
    orange3?: string;
    orange4?: string;
    blue2?: string;
    blue3?: string;
    blue4?: string;

    borderRadius?: {
      searchBox?: string;
      modal?: string;
      popup?: string;
      tab?: string;
      badge?: string;
      button?: string;
      checkbox?: string;
      input?: string;
    };
    boxShadow?: {
      boxShadow01?: string;
      boxShadow02?: string;
      customShadow?: string;
      popupShadow?: string;
    };
  }

  interface CustomTypography {
    heading?: React.CSSProperties;
    heading1?: React.CSSProperties;
    heading2?: React.CSSProperties;
    heading3?: React.CSSProperties;
    title?: React.CSSProperties;
    contentBold?: React.CSSProperties;
    contentHyperlink?: React.CSSProperties;
    contentRegular?: React.CSSProperties;
    contentRegularBold?: React.CSSProperties;
    captionSmallBold?: React.CSSProperties;
    captionMedium?: React.CSSProperties;
    captionRegular?: React.CSSProperties;
    captionItalic?: React.CSSProperties;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypographyVariants extends CustomTypography {}
  interface TypographyVariantsOptions extends CustomTypography {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading?: true;
    heading1?: true;
    heading2?: true;
    heading3?: true;
    title?: true;
    contentBold?: true;
    contentRegular?: true;
    contentRegularBold?: true;
    captionSmallBold?: true;
    captionMedium?: true;
    captionRegular?: true;
    captionItalic?: true;
    contentHyperlink?: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    minWidth: true; // adds the `mobile` breakpoint
    maxWidth: true;
  }
}
